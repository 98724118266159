import { useNode } from '@craftjs/core';
import React, {useEffect, useState} from 'react';
import { CountdownSettings } from './CountdownSettings';
export const CountdownTimer = (props) => {
    const { connectors: { connect }, } = useNode((node) => ({
        selected: node.events.selected,
    }));
    const [difference, setDifference] = useState(0);
    useEffect(() => {
        let endTime =  props.timer * 24 * 60 * 60 * 1000;
        setDifference(Date.now() + endTime)

    }, [props.timer])
    const calculateTimeLeft = () => {

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());


    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    })

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
        );
    });
    return (<div ref={connect}>
        <div>
            {timerComponents.length ? timerComponents : <span>Time's up!</span>}
        </div>  </div>);
};
CountdownTimer.craft = {
    displayName: 'Countdown',
    props: {
        timer: '1000',
    },
    related: {
        toolbar: CountdownSettings,
    },
};
