import { useEditor } from '@craftjs/core';
import { Layers } from '@craftjs/layers';
import React, { useState } from 'react';
import styled from 'styled-components';
import { SidebarItem } from './SidebarItem';
import { ReactComponent as CustomizeIcon } from '../../../../icons/customize.svg';
import { ReactComponent as LayerIcon } from '../../../../icons/layers.svg';
import { Toolbar } from '../../Toolbar';
export const SidebarDiv = styled.div `
  width: 280px;
  opacity: ${(props) => (props.enabled ? 1 : 0)};
  background: #fff;
  margin-right: ${(props) => (props.enabled ? 0 : -280)}px;
`;
export const Sidebar = () => {
    const [layersVisible, setLayerVisible] = useState(true);
    const [toolbarVisible, setToolbarVisible] = useState(true);
    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));
    return (<SidebarDiv enabled={enabled} className="sidebar transition bg-white w-2">
      <div className="flex flex-col h-full">
        <SidebarItem icon={CustomizeIcon} title="Customize" height={!layersVisible ? 'full' : '55%'} visible={toolbarVisible} onChange={(val) => setToolbarVisible(val)}>
          <Toolbar />
        </SidebarItem>
        <SidebarItem icon={LayerIcon} title="Layers" height={!toolbarVisible ? 'full' : '45%'} visible={layersVisible} onChange={(val) => setLayerVisible(val)}>
          <div className="">
            <Layers expandRootOnLoad={true}/>
          </div>
        </SidebarItem>
      </div>
    </SidebarDiv>);
};
