import { useNode } from '@craftjs/core';
import React from 'react';
import { ImageSettings } from './ImageSettings';
export const Image = (props) => {
    const { connectors: { connect }, } = useNode((node) => ({
        selected: node.events.selected,
    }));
    const { url } = props;
    return (<div ref={connect}>
        <img src={url} alt={"test"} />
    </div>);
};
Image.craft = {
    displayName: 'Countdown',
    props: {
        url: 'https://picsum.photos/200/300',
    },
    related: {
        toolbar: ImageSettings,
    },
};
