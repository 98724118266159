import React from 'react';
import { ToolbarSection, ToolbarItem } from '../../editor';
export const ImageSettings = () => {
    return (<React.Fragment>
        <ToolbarSection title="Url" props={['background', 'color']} summary={({ background, color }) => {
            return (<div className="flex flex-row-reverse">
                <div style={{
                    background: background && `rgba(${Object.values(background)})`,
                }} className="shadow-md flex-end w-6 h-6 text-center flex items-center rounded-full bg-black">
                    <p style={{
                        color: color && `rgba(${Object.values(color)})`,
                    }} className="text-white w-full text-center">
                    </p>
                </div>
            </div>);
        }}>
            <ToolbarItem full={true} propKey="url" type="text" label="Url"/>
        </ToolbarSection>

    </React.Fragment>);
};
