import { useEditor } from '@craftjs/core';
import { Tooltip,  Button as MaterialButton, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import cx from 'classnames';
import React, { useState } from 'react';
import styled from 'styled-components';
import lz from "lzutf8";
import copy from 'copy-to-clipboard';
import axios from "axios";
import { ReactComponent as Checkmark} from '../../../icons/check.svg';
import { ReactComponent as Customize } from '../../../icons/customize.svg';
import { ReactComponent as RedoSvg } from '../../../icons/toolbox/redo.svg';
import { ReactComponent as UndoSvg } from '../../../icons/toolbox/undo.svg';

const apiUrl = "http://landerpage.test/api/pages";
const HeaderDiv = styled.div `
  width: 100%;
  height: 45px;
  z-index: 99999;
  position: relative;
  padding: 0px 10px;
  background: #d4d4d4;
  display: flex;
`;
const Btn = styled.a `
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 3px;
  color: #fff;
  font-size: 13px;
  svg {
    margin-right: 6px;
    width: 12px;
    height: 12px;
    fill: #fff;
    opacity: 0.9;
  }
`;
const Item = styled.a `
  margin-right: 10px;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    fill: #707070;
  }
  ${(props) => props.disabled &&
    `
    opacity:0.5;
    cursor: not-allowed;
  `}
`;

export const Header = () => {
    const { enabled, canUndo, canRedo, actions, query } = useEditor((state, query) => ({
        enabled: state.options.enabled,
        canUndo: query.history.canUndo(),
        canRedo: query.history.canRedo(),
    }));
    const [dialogOpen, setDialogOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState();
    const [stateToLoad, setStateToLoad] = useState(null);
    return (<HeaderDiv className="header text-white transition w-full">
      <div className="items-center flex w-full px-4 justify-end">
        {enabled && (<div className="flex-1 flex">
            <Tooltip title="Undo" placement="bottom">
              <Item disabled={!canUndo} onClick={() => actions.history.undo()}>
                <UndoSvg />
              </Item>
            </Tooltip>
            <Tooltip title="Redo" placement="bottom">
              <Item disabled={!canRedo} onClick={() => actions.history.redo()}>
                <RedoSvg />
              </Item>
            </Tooltip>
          </div>)}
          <div className="flex">
              <MaterialButton
                  className="copy-state-btn"
                  size="small"
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                      const json = query.serialize();
                      copy(lz.encodeBase64(lz.compress(json)));
                      setSnackbarMessage("State copied to clipboard")
                  }}
              >
                  Copy current state
              </MaterialButton>
          </div>
          <MaterialButton
              className="load-state-btn"
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => setDialogOpen(true)}
          >
              Load
          </MaterialButton>
          <Dialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              fullWidth
              maxWidth="md"
          >
              <DialogTitle id="alert-dialog-title">Load state</DialogTitle>
              <DialogContent>
                  <TextField
                      multiline
                      fullWidth
                      placeholder='Paste the contents that was copied from the "Copy Current State" button'
                      size="small"
                      value={stateToLoad}
                      onChange={e => setStateToLoad(e.target.value)}
                  />
              </DialogContent>
              <DialogActions>
                  <MaterialButton onClick={() => setDialogOpen(false)} color="primary">
                      Cancel
                  </MaterialButton>
                  <MaterialButton
                      onClick={() => {
                          setDialogOpen(false);
                          const json = lz.decompress(lz.decodeBase64(stateToLoad));
                          actions.deserialize(json);
                          setSnackbarMessage("State loaded")
                      }}
                      color="primary"
                      autoFocus
                  >
                      Load
                  </MaterialButton>
              </DialogActions>
          </Dialog>
          <Snackbar
              autoHideDuration={1000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={!!snackbarMessage}
              onClose={() => setSnackbarMessage(null)}
              message={<span>{snackbarMessage}</span>}
          />
        <div className="flex">
          <Btn className={cx([
        'transition cursor-pointer',
        {
            'bg-green-400': enabled,
            'bg-primary': !enabled,
        },
    ])} onClick={() => {
        actions.setOptions((options) => (options.enabled = !enabled));
    }}>
            {enabled ? <Checkmark /> : <Customize />}
            {enabled ? 'Finish Editing' : 'Edit'}
          </Btn>
        </div>
      </div>
    </HeaderDiv>);
};
