import React from 'react';
import { ToolbarSection, ToolbarItem } from '../../editor';
export const CountdownSettings = () => {
    return (<React.Fragment>
        <ToolbarSection title="Url" props={['timer']} summary={({ timer }) => {
            return (<div className="flex flex-row-reverse">
                <div>
                    <p>
                        {timer} Days
                    </p>
                </div>
            </div>);
        }}>
            <ToolbarItem full={true} propKey="timer" type="number" label="timer"/>
        </ToolbarSection>

    </React.Fragment>);
};
